<template>
  <div class="ep-promotion-footer">
    <div class="ep-promotion-footer-inner">
      <template v-if="proFooterTitle">
        <div class="ep-promotion-footer-title">{{ proFooterTitle }}</div>
        <div class="ep-promotion-footer-desc" v-html="proFooterInformation" v-if="proFooterInformation"></div>
      </template>
      <div class="ep-promotion-footer-license" v-if="licenseNo1">{{ licenseNo1 }}</div>
      <div class="ep-promotion-footer-license" v-if="licenseNo2">{{ licenseNo2 }}</div>
      <div class="ep-promotion-footer-attention" v-if="proAttention" v-html="proAttention"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpPromotionFooter",
  props: {
    proFooterTitle: {
      type: String,
      default: ""
    },
    proFooterInformation: {
      type: String,
      default: ""
    },
    licenseNo1: {
      type: String,
      default: ""
    },
    licenseNo2: {
      type: String,
      default: ""
    },
    proAttention: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss">
.ep-promotion-footer {
  background-color: #f5f6f7;
  font-size: 11px;
  line-height: (17/11);
  letter-spacing: -0.01em;
  color: #666;

  &-inner {
    padding: 33px 24px;
  }

  &-title {
    margin-bottom: 10px;
    color: $color-grey-6;
    font-weight: 700;
    font-size: 14px;
  }

  &-desc {
    //opacity: 0.8;
    p {
      margin: 0;
      line-height: 20px;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0 0 0 12px;

    li {
      margin: 5px 0 0;

      &:first-child {
        margin-top: 0;
      }

      &:before {
        display: inline-block;
        margin: 0 5px 0 -12px;
        content: "\00B7";
      }
    }
  }

  &-license {
    //padding: 0 0 0 12px;
    letter-spacing: -0.5px;
    //color: #666;
    word-wrap: break-word; /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap; /* current browsers */
  }

  &-attention {
    //opacity: 0.9;
    p {
      margin: 0;
    }
  }

  &-desc + &-license {
    //margin-top: 20px;
  }

  &-license + &-information {
    margin-top: 3px;
  }
}
</style>
