export const splitAnnualTitle = (value) => {
  const regex = new RegExp("국내*|해외*");
  const isValidText = regex.test(value);

  if (!isValidText) {
    return value;
  }

  return /(국내)|(해외)/gm.exec(value)[0];
};

export const currencyKR = (value, type = "") => {
  if (!value) return "0원";

  const koreanUnits = ["", "만", "억", "조"];
  let answer = "";
  let unit = 10000;
  let index = 0;
  let division = Math.pow(unit, index);

  while (Math.floor(value / division) > 0) {
    const mod = Math.floor((value % (division * unit)) / division);
    if (mod) {
      let modToString = mod.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      if (type === "2" && mod.toString().length > 3) {
        // 천단위도 한글로 표시
        modToString = mod.toString().replace(/(\d{3})+(?!\d)/g, "천");
      }

      //if (koreanUnits[index] === "억") {
      answer = `${modToString}${koreanUnits[index]} ` + answer;
      //} else {
      //  answer = `${modToString}${koreanUnits[index]}` + answer;
      //}
    }
    division = Math.pow(unit, ++index);
  }

  return answer === "" ? "0원" : answer + "원";
};
