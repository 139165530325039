<template>
  <div class="ep-promotion-summary">
    <img :src="companyDetailImgPath" class="ep-promotion-summary-img" alt="프로모션 상세 이미지" />
    <!-- desc1 -->
    <div class="ep-promotion-summary-event" v-if="desc1 || desc2 || entryUrl || outLinkUrl">
      <div class="ep-promotion-summary-event-description1" v-if="desc1">{{ desc1 }}</div>
      <div class="ep-promotion-summary-event-description2" v-if="desc2">{{ desc2 }}</div>
      <div class="ep-promotion-summary-event-buttons-wrapper d-flex justify-space-between align-center">
        <button
          type="button"
          class="ep-promotion-summary-event-apply-button"
          :data-entry-btn-nm="entryBtnNm"
          :data-entry-url="entryUrl"
          :data-entry-type="entryType"
          @click="onClickButton"
          v-if="entryUrl"
        >
          <span v-if="entryBtnNm">{{ entryBtnNm }}</span>
          <span v-else>응모하기</span>
        </button>
        <button
          type="button"
          class="ep-promotion-summary-event-apply-button"
          :data-out-link-btn-nm="outLinkBtnNm"
          :data-out-link-url="outLinkUrl"
          :data-out-link-type="outLinkType"
          @click="onClickButton"
          v-if="outLinkUrl"
        >
          <span v-if="outLinkBtnNm">{{ outLinkBtnNm }}</span>
          <span v-else>이벤트 보기</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpPromotionSummary",
  props: {
    companyDetailImgPath: {
      type: String,
      default: ""
    },
    desc1: {
      type: String,
      default: ""
    },
    desc2: {
      type: String,
      default: ""
    },
    entryBtnNm: {
      type: String,
      default: "응모하기"
    },
    entryUrl: {
      type: String,
      default: ""
    },
    entryType: {
      type: String,
      default: ""
    },
    outLinkBtnNm: {
      type: String,
      default: "이벤트 보기"
    },
    outLinkUrl: {
      type: String,
      default: ""
    },
    outLinkType: {
      type: String,
      default: ""
    }
  },
  methods: {
    onClickButton(e) {
      this.$emit("click-button", e);
    }
  }
};
</script>
<style lang="scss">
.ep-promotion-summary {
  &-img {
    display: block;
    width: 100%;
    height: auto;
  }

  &-event {
    background-color: #f5f6f7;
    padding: 19px 24px;

    &-description1 {
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.2px;
      color: #858585;
      margin-left: 4px;
    }

    &-description2 {
      text-align: center;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.2px;
      color: $color-grey-3;
      margin: 0 0 0 4px;
    }

    &-buttons-wrapper {
      margin-top: 20px;
      flex-flow: row-reverse;
    }

    &-apply-button {
      width: 100%;
      height: 52px;
      padding: 12px 20px 16px;
      background: $color-grey-6;
      border: none;
      border-radius: 8px;

      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      letter-spacing: -0.01em;

      cursor: pointer;

      &:focus {
        // remove default browser's focus behavior
        outline: none;
      }

      &:nth-child(2) {
        background-color: transparent;
        border: 1px solid #7a7a7a;
        margin-right: 7px;
        color: #000;
      }
    }
  }
}
</style>
