<template>
  <div class="ep-promotion-target-card">
    <div class="ep-promotion-target-card-information">
      <div class="ep-promotion-target-card-inner" role="text">
        <div class="ep-promotion-target-card-name">{{ cardName }}</div>
        <div class="ep-promotion-target-card-description">{{ desc1 }}</div>
        <div class="ep-promotion-target-card-description">{{ desc2 }}</div>
        <div class="ep-promotion-target-card-annual" v-if="annualTextList.length > 0">
          <div v-for="(item, index) in annualTextList" :key="index" class="ep-promotion-target-card-annual-item">
            <span class="ep-promotion-target-card-annual-type">{{ item.title | annualTitle }}</span>
            <span class="ep-promotion-target-card-annual-amount">{{ item.annual }}</span>
          </div>
        </div>
      </div>
      <ep-image class="ep-promotion-target-card-image" :img-path="imgPath" :img-desc="cardName" />
    </div>
    <div class="ep-promotion-target-card-additional-information">
      <ep-benefit name="지원금" :support-text="`${supportText} 지급`" v-if="supportText" />
      <button
        type="button"
        class="ep-promotion-target-card-button"
        :data-card-seq="cardSeq"
        :data-destination-url="destinationUrl"
        :data-destination-type="destinationType"
        :data-gp-mapp-seq="gpMappSeq"
        :data-ads-dtl-seq="adsDtlSeq"
        :data-ads-seq="adsSeq"
        :data-pay-yn="payYn"
        :data-card-nm="cardName"
        @click="onClickButton"
      >
        {{ detailBtnNm ? detailBtnNm : "자세히 보기" }}
      </button>
    </div>
  </div>
</template>

<script>
import EpImage from "@/components/common/EpImage";
import EpBenefit from "@/components/common/EpBenefit";
import { splitAnnualTitle } from "@/helpers/filter";

export default {
  name: "EpPromotionTargetCard",
  components: {
    EpBenefit,
    EpImage
  },
  props: {
    cardSeq: {
      type: Number,
      default: null
    },
    cardName: {
      type: String,
      default: ""
    },
    imgPath: {
      type: String,
      default: ""
    },
    desc1: {
      type: String,
      default: ""
    },
    desc2: {
      type: String,
      default: ""
    },
    annualTextList: {
      type: Array,
      default: () => []
    },
    supportText: {
      type: String,
      default: ""
    },
    destinationUrl: {
      type: String,
      default: ""
    },
    destinationType: {
      type: String,
      default: ""
    },
    gpMappSeq: {
      type: Number,
      default: null
    },
    adsDtlSeq: {
      type: Number,
      default: null
    },
    adsSeq: {
      type: Number,
      default: null
    },
    payYn: {
      type: String,
      default: "N"
    },
    detailBtnNm: {
      type: String,
      default: "자세히 보기"
    }
  },
  filters: {
    annualTitle: splitAnnualTitle
  },
  methods: {
    onClickButton(e) {
      this.$emit("click-button", e);
    }
  }
};
</script>

<style lang="scss">
.ep-promotion-target-card {
  position: relative;
  padding: rem(30px) rem(24px) rem(17px) rem(24px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 8px 15px rgba(218, 218, 218, 0.2);

  &-inner {
    flex: 1;
  }

  &-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-additional-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(15px);
    padding-top: 13px;
    border-top: 1px solid $color-grey-1;
    line-height: (42/15); // 20 + 10 + 12
  }

  &-name {
    @include ellipsis(1);
    margin-bottom: 8px;
    font-weight: 700;
    font-size: rem(18px);
    line-height: (27/18);
    letter-spacing: -0.2px;
    color: $color-grey-6;
  }

  &-description {
    @include ellipsis(1);
    font-weight: 400;
    font-size: rem(14px);
    line-height: (20/14);
    letter-spacing: -0.3px;
    color: $color-grey-4;
  }

  &-annual {
    display: flex;
    margin-top: 8px;

    &-item {
      margin-left: 5px;

      &:first-child {
        margin-left: 0;
      }
    }

    &-type {
      margin-right: 3px;
      font-weight: 700;
      font-size: rem(13px);
      line-height: (21/13);
      letter-spacing: -0.2px;
      color: $color-grey-3;
    }

    &-amount {
      margin-right: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: rem(13px);
      line-height: (21/13);
      letter-spacing: -0.2px;
      color: #666;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-image {
    width: rem(60px);
    margin-left: 9px;
    box-shadow: 3px 4px 12px rgba(142, 156, 173, 0.2);
  }

  &-button {
    flex: 1;
    padding: rem(10px) rem(20px) rem(12px);
    margin-left: auto;
    border: none;
    border-radius: 4px;
    background: $color-grey-1;
    font-size: rem(14px);
    line-height: (20/14);
    text-align: center;
    letter-spacing: -0.006em;
    color: $color-grey-5;
    cursor: pointer;
    outline: none;
  }

  .ep-benefit + &-button {
    flex: none;
  }
}
</style>
