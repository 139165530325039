<template>
  <div class="ep-promotion-description">
    <ep-description-table-row v-if="startDtText && endDtText && isShowDate">
      <template #title>
        <span>행사기간</span>
      </template>
      <template #content>
        <strong>{{ startDtText }} ~ {{ endDtText }}</strong>
      </template>
    </ep-description-table-row>
    <ep-description-table-row v-if="proTarget">
      <template #title>
        <span>대상고객</span>
      </template>
      <template #content>
        <div v-html="proTarget"></div>
      </template>
    </ep-description-table-row>
    <ep-description-table-row v-if="proDesc">
      <template #title>
        <span>행사내용</span>
      </template>
      <template #content>
        <div v-html="proDesc"></div>
      </template>
    </ep-description-table-row>
    <ep-description-table-row v-if="confirmStartDtText && confirmEndDtText">
      <template #title>
        <span>이용기간</span>
      </template>
      <template #content>
        <span>{{ confirmStartDtText }} - {{ confirmEndDtText }}</span>
      </template>
    </ep-description-table-row>
    <ep-description-table-row v-if="proReward">
      <template #title>
        <span>혜택 제공 방법</span>
      </template>
      <template #content>
        <div v-html="proReward" role="text"></div>
      </template>
    </ep-description-table-row>
    <ep-foldable-description title="안내 사항" v-if="proInformation">
      <div v-html="proInformation" role="text"></div>
    </ep-foldable-description>
  </div>
</template>

<script>
import EpDescriptionTableRow from "@/components/detail/EpDescriptionTableRow";
import EpFoldableDescription from "@/components/detail/EpFoldableDescription";

export default {
  name: "EpPromotionDescription",
  components: {
    EpDescriptionTableRow,
    EpFoldableDescription
  },
  props: {
    isShowDate: {
      type: Boolean,
      default: true
    },
    startDtText: {
      type: String
    },
    endDtText: {
      type: String
    },
    proTarget: {
      type: String
    },
    proDesc: {
      type: String
    },
    confirmStartDtText: {
      type: String
    },
    confirmEndDtText: {
      type: String
    },
    proReward: {
      type: String
    },
    proAttention: {
      type: String
    },
    proInformation: {
      type: String
    }
  }
};
</script>

<style lang="scss">
.ep-promotion-description {
  background-color: #fff;
}
</style>
