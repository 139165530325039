<template>
  <div class="ep-promotion-target-card-list" v-if="data && data.length > 0">
    <component :is="cardComponent" v-bind="card" v-for="(card, index) in data" :key="index" @click-button="(e) => onClickButton(e, index)"></component>
  </div>
</template>

<script>
import EpPromotionTargetCard from "@/components/promotion/EpPromotionTargetCard";
import EpPromotionTargetCardLoan from "@/components/promotion/EpPromotionTargetCardLoan";

export default {
  name: "EpPromotionTargetCardList",
  components: {
    EpPromotionTargetCard,
    EpPromotionTargetCardLoan
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    cardType: {
      type: String,
      default: "" // loan: 추천 대출 상품 카드
    }
  },
  computed: {
    cardComponent() {
      switch (this.cardType) {
        case "loan":
          return EpPromotionTargetCardLoan;
        default:
          return EpPromotionTargetCard;
      }
    }
  },
  methods: {
    onClickButton(clickedButtonEvent, index) {
      this.$emit("click-button", clickedButtonEvent, index);
    }
  }
};
</script>

<!--
TestData

data: {
      type: Array,
      default: () => [
        {
          name: '신한 Mr.Life',
          image: require('@/assets/images/temp-shinhan-card.png'),
          desc1: '6대 생활영역 1% 캐시백',
          desc2: '많이 사용한 영역 1% 캐시백',
          annualFee: {
            local: '1만5천원',
            global: '1만8천원'
          },
          bnfTotal: '15만원',
          onClickButton: () => {
            window.open('https://www.shinhancard.com/pconts/html/landing/bestCardA.html?&gclid=Cj0KCQiAvbiBBhD-ARIsAGM48bwFZRSIum6XCakB6BAgKRuQHUN7vdVjS7THRAGddTjUYmARgE0XgAYaAh1qEALw_wcB#life')
          }
        },
        {
          name: '신한 Deep Oil',
          image: require('@/assets/images/temp-shinhan-card-oil.png'),
          desc1: '직접 고른 주유소에서 10% 할인',
          desc2: '꼭 필요한 정비소와 주차장 10% 할인',
          annualFee: {},
          bnfTotal: '',
          onClickButton: () => {
            window.open('https://www.shinhancard.com/conts/person/card_info/dream/credit/oil/1499476_46596.jsp')
          }
        },
        {
          name: '신한 YOLO',
          image: require('@/assets/images/temp-shinhan-card-yolo.png'),
          desc1: '6개 영역 할인율을 내 마음대로',
          desc2: '(20% 1개, 15% 2개, 10% 3개)',
          annualFee: {
            local: '1만5천원',
            global: '1만8천원'
          },
          bnfTotal: ''
        },
        {
          name: '신한 B.Big',
          image: require('@/assets/images/temp-shinhan-card-big.png'),
          desc1: '대중교통 일 200~600원 할인',
          desc2: '택시/KTX 10% 할인',
          annualFee: {
            local: '1만5천원',
            global: '1만8천원'
          },
          bnfTotal: '15만원'
        }
      ]
    }
-->

<style lang="scss">
.ep-promotion-target-card-list {
  .ep-promotion-target-card {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }

  .ep-promotion-loan-card-wrap {
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
