<template>
  <detail-container :has-header="false" pageTitle="프로모션" :is-page-loaded="isLoadedData">
    <ep-promotion-summary v-bind="summary" @click-button="onClickButton" />

    <section class="card-section promotion-card-list-contents" v-if="isCardList">
      <div class="card-section-contents ">
        <ep-promotion-target-card-list :data="cardList" cardType="loan" @click-button="onClickCardButton" />
      </div>
    </section>

    <section class="card-section promotion-description">
      <div class="card-section-contents">
        <ep-promotion-description v-bind="description" />
      </div>
    </section>
    <ep-promotion-footer v-bind="footer" />
  </detail-container>
</template>

<style lang="scss" scoped>
.promotion-card-list-contents {
  margin-top: 0;
  padding-bottom: 24px;
  background-color: #f5f6f7;

  .card-section-contents {
    padding-top: 5px;
  }
}

.promotion-description {
  margin-top: 0;
  padding-bottom: 0;
}
</style>

<script>
import DetailContainer from "@/components/layout/DetailContainer";
import { userGetters, loanPromotionGetters, loanPromotionActions, uiActions, userActions } from "@/store";

import { ROUTES_NAME } from "@/const/router";
import EpPromotionSummary from "@/components/promotion/EpPromotionSummary";
import EpPromotionTargetCardList from "@/components/promotion/EpPromotionTargetCardList";
import EpPromotionDescription from "@/components/promotion/EpPromotionDescription";
import EpPromotionFooter from "@/components/promotion/EpPromotionFooter";
import { traceEvent, gaTracker } from "@/helpers/tracer";
import { move } from "@/helpers/move";
import { AppCommand } from "@/api/bridge";
import { CREATIVE_DTL_TYPE, DESTINATION_TYPE, OCB_TRACK_ID } from "@/const";

export default {
  name: ROUTES_NAME.LOAN_PROMOTION,
  components: {
    DetailContainer,
    EpPromotionSummary,
    EpPromotionTargetCardList,
    EpPromotionDescription,
    EpPromotionFooter
  },
  data() {
    return {
      initialize: false, // to call only once
      isLoadedData: false // to show skeleton UI
    };
  },
  computed: {
    ...userGetters(["trackCdType", "userInfo", "isInitialUser"]),
    ...loanPromotionGetters(["summary", "description", "cardList", "isCardList", "footer"])
  },
  watch: {
    userInfo: {
      immediate: true,
      async handler(v) {
        // do not use isLoadedData
        if (Object.keys(v).length > 0 && !this.initialize) {
          this.initialize = true;

          // EBMP 미가입자인 경우 약관 전체 미동의로 우리쪽에 데이터를 쌓는다 (PUSH 등으로 바로 들어온 경우 대비)
          // 반드시 getData 보다 먼저 와야 한다. 로깅을 적절히 해야하고, 신규 페이지에서 바로 재차 신규 생성 요청이 들어갈 수 있기 때문에
          if (this.isInitialUser) {
            await this.setAgreeInfo({
              agreeInfoForm: {
                trdInfo: false
              }
            });
          }

          await this.getData();

          this.$bridge.requestToApp(AppCommand.TRACK_LOG, { log: { page_id: OCB_TRACK_ID.PROMOTION, common_code: this.summary.compNm } });
        }
      }
    }
  },
  methods: {
    ...userActions(["setAgreeInfo"]),
    ...uiActions(["setBridgeDialog"]),
    ...loanPromotionActions(["fetchLoanPromotion"]),

    onClickButton(clickElement) {
      const { gp_mapp_seq, ads_seq, pay_yn } = this.$route.query;
      const { dataset } = clickElement.currentTarget;
      const { outLinkUrl, outLinkType, outLinkBtnNm } = dataset;
      if (!outLinkUrl) return console.error("outLinkUrl is not defined");

      //A010 : 대출외부링크
      //A011 : 카드외부링크
      this.wrapTraceEvent(
        {
          gpMappSeq: gp_mapp_seq,
          adsSeq: ads_seq,
          payYn: pay_yn,
          promotionSeq: this.$route.params.promotionSeq
        },
        "A010"
      );

      gaTracker({
        category: `[${this.summary.proName}] 대출 프로모션 페이지`,
        action: `[${outLinkBtnNm}] 버튼 클릭`,
        label: `${this.trackCdType}`
      });

      // 과거 없던 컬럼으로 데이터 없을 수 있음 (I - IN APP(I) OR O - OUT LINK(C))
      move({
        destinationUrl: outLinkUrl,
        destinationType: outLinkType ? outLinkType : DESTINATION_TYPE.OUT_LINK,
        gpMappSeq: gp_mapp_seq,
        adsSeq: ads_seq,
        payYn: pay_yn
      });
    },

    onClickCardButton(clickElement, index) {
      const { gp_mapp_seq, ads_seq, pay_yn } = this.$route.query;
      const { promotionSeq } = this.$route.params;
      const { dataset } = clickElement.currentTarget;

      // promotionSeq, destinationType, destinationUrl
      move(
        { ...dataset, type: CREATIVE_DTL_TYPE.LOAN },
        {
          P: () => {
            this.$router.push({
              name: ROUTES_NAME.LOAN_PROMOTION,
              params: { promotionSeq: promotionSeq },
              query: this.$route.query
            });
          }
        }
      );

      gaTracker({
        category: `[${this.summary.proName}] 대출 프로모션 페이지`,
        action: `[${dataset.loanNm}] 자세히보기 버튼 클릭`,
        label: `${this.trackCdType}`
      });

      this.wrapTraceEvent(
        {
          gpMappSeq: gp_mapp_seq,
          adsSeq: ads_seq,
          subAdsSeq: dataset.adsSeq,
          payYn: pay_yn,
          promotionSeq
        },
        "A004"
      );

      this.$bridge.requestToApp(AppCommand.TRACK_LOG, {
        log: { page_id: OCB_TRACK_ID.PROMOTION, action_id: "list_tap.financialproduct", category_name: dataset.typeNm, common_code: dataset.loanNm, display_order: index }
      });
    },
    wrapTraceEvent(data, eventType) {
      let { gpMappSeq, adsSeq, payYn, subAdsSeq, promotionSeq } = data;

      traceEvent({
        eventType: eventType,
        gpMappSeq,
        adsSeq,
        subAdsSeq,
        payYn,
        promotionSeq
      });
    },
    displayedPageTracer() {
      const { gp_mapp_seq, ads_seq, pay_yn, from_push } = this.$route.query;
      const { promotionSeq } = this.$route.params;

      if (from_push) {
        this.wrapTraceEvent(
          {
            gpMappSeq: gp_mapp_seq,
            adsSeq: ads_seq,
            payYn: pay_yn,
            promotionSeq
          },
          "I008"
        );
      }
      this.wrapTraceEvent(
        {
          gpMappSeq: gp_mapp_seq,
          adsSeq: ads_seq,
          payYn: pay_yn,
          promotionSeq
        },
        "I002"
      );
    },
    async getData() {
      const { promotionSeq } = this.$route.params;

      this.isLoadedData = false;

      await this.fetchLoanPromotion({
        promotionSeq: promotionSeq
      });

      this.isLoadedData = true;
      this.displayedPageTracer();
    }
  }
};
</script>
