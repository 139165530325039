<template>
  <div>
    <div class="ep-description-table-row">
      <span class="ep-description-table-row-title">
        <slot name="title"></slot>
      </span>
      <span :class="['ep-description-table-row-content', isContentBold ? 'bold' : '']">
        <slot name="content"></slot>
        <span v-if="hasFoldableSlot" class="ep-description-table-row-fold-button" @click="isFolded = !isFolded">
          <img :class="['ep-description-table-row-fold-chevron', isFolded ? 'fold' : '']" src="@/assets/icons/chevron-down-icon--grey.png" alt="펼치기 아이콘" />
        </span>
      </span>
    </div>
    <div v-if="hasFoldableSlot && !isFolded" class="ep-description-table-row-foldable-content">
      <slot name="foldable"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EpDescriptionTableRow",
  props: {
    isContentBold: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFolded: true
    };
  },
  computed: {
    hasFoldableSlot() {
      return !!this.$slots["foldable"] || !!this.$scopedSlots["foldable"];
    }
  }
};
</script>

<style lang="scss">
.ep-description-table-row {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  //border-top: 1px solid $color-grey-1;
  border-bottom: 1px solid $color-grey-1;
  //&:first-child {
  //  border-top: 0;
  //}

  &-title {
    width: 92px;
    flex-shrink: 0;

    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.5px;
    color: #1b1b1b;
  }

  &-content {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #666666;
    flex-grow: 1;
    margin-left: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.bold {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
    }

    p {
      margin: 0;
      line-height: 20px;
    }
  }

  &-fold-button {
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;

    margin-right: 11.39px;

    flex-shrink: 0;
    flex-grow: 0;
  }

  &-fold-chevron {
    width: 12px;
    height: 7.41px;
    transform: rotate(180deg);
    vertical-align: middle;

    &.fold {
      transform: rotate(0deg);
    }
  }
}
</style>
