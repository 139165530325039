<template>
  <div class="ep-promotion-loan-card-wrap">
    <div class="ep-promotion-loan-card">
      <div class="ep-promotion-loan-card-information" role="text">
        <div class="ep-promotion-loan-card-type">{{ typeNm }}</div>
        <div class="ep-promotion-loan-card-name">{{ loanName }}</div>
        <div class="ep-promotion-loan-card-inner">
          <dl class="ep-promotion-loan-card-desc">
            <dt>금리(연)</dt>
            <dd>
              <strong>{{ interestMinRate }}</strong>
              <em v-if="interestMaxRate"> ~ {{ interestMaxRate }}%</em>
            </dd>
          </dl>
          <dl class="ep-promotion-loan-card-desc">
            <dt>최대</dt>
            <dd>
              <strong>{{ creditLineText }}</strong>
            </dd>
          </dl>
        </div>
        <div class="ep-promotion-loan-card-license" v-if="licenseNo1">{{ licenseNo1 }}</div>
      </div>
      <div class="ep-promotion-loan-card-additional-information">
        <template v-if="advtTypeCd === '10'">
          <ep-benefit name="대출이자" :support-text="`${parseInt(support, 10)}% 지원`" v-if="support && support !== '0'" />
        </template>
        <button
          type="button"
          class="ep-promotion-loan-card-button"
          :data-promotion-seq="promotionSeq"
          :data-destination-url="destinationUrl"
          :data-destination-type="destinationType"
          :data-gp-mapp-seq="gpMappSeq"
          :data-ads-dtl-seq="adsDtlSeq"
          :data-ads-seq="adsSeq"
          :data-pay-yn="payYn"
          :data-loan-nm="loanName"
          :data-type-nm="typeNm"
          @click="onClickButton"
        >
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EpBenefit from "@/components/common/EpBenefit";

export default {
  name: "EpPromotionTargetLoan",
  components: {
    EpBenefit
  },
  props: {
    promotionSeq: {
      type: String,
      default: ""
    },
    loanName: {
      type: String,
      default: ""
    },
    typeNm: {
      type: String,
      default: ""
    },
    creditLineText: {
      type: String,
      default: ""
    },
    interestMinRate: {
      type: String,
      default: ""
    },
    interestMaxRate: {
      type: String,
      default: ""
    },
    licenseNo1: {
      type: String,
      default: ""
    },
    destinationUrl: {
      type: String,
      default: ""
    },
    destinationType: {
      type: String,
      default: ""
    },
    advtTypeCd: {
      type: String,
      default: ""
    },
    support: {
      type: String,
      default: ""
    },
    buttonText: {
      type: String,
      default: "내 한도 확인"
    },
    gpMappSeq: {
      type: Number,
      default: null
    },
    adsDtlSeq: {
      type: Number,
      default: null
    },
    adsSeq: {
      type: Number,
      default: null
    },
    payYn: {
      type: String,
      default: "N"
    }
  },
  methods: {
    onClickButton(e) {
      this.$emit("click-button", e);
    }
  }
};
</script>

<style lang="scss">
.ep-promotion-loan-card {
  position: relative;
  padding: rem(30px) rem(24px) rem(16px) rem(24px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0 8px 15px rgba(218, 218, 218, 0.2);

  &-wrap {
  }

  &-type {
    font-size: rem(14px);
    line-height: (20/14);
    letter-spacing: -0.2px;
    color: $color-grey-5;
  }

  &-license {
    margin-top: 5px;
    font-size: 12px;
    line-height: (18/12);
    letter-spacing: -0.5px;
    color: $color-grey-3;
    word-wrap: break-word; /* IE 5.5-7 */
    white-space: -moz-pre-wrap; /* Firefox 1.0-2.0 */
    white-space: pre-wrap; /* current browsers */
  }

  &-button {
    flex: 1;
    -webkit-appearance: none;
    display: inline-block;
    background: $color-grey-6;
    border: none;
    border-radius: 4px;
    height: rem(42px);
    padding: 0 rem(20px);
    font-size: rem(14px);
    letter-spacing: -0.006em;
    color: #fff;
  }

  .ep-benefit + &-button {
    flex: none;
  }

  &-additional-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(15px);
    padding-top: 13px;
    border-top: 1px solid $color-grey-1;
    line-height: (42/15); // 20 + 10 + 12
  }

  &-name {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: rem(18px);
    line-height: (27/18);
    letter-spacing: -0.2px;
    color: $color-grey-6;
  }

  &-inner {
    display: flex;
    flex-wrap: wrap;
  }

  &-desc {
    position: relative;
    display: flex;
    align-items: center;
    flex: none;
    margin: 0;
    font-size: 14px;
    line-height: (21/14);
    letter-spacing: -0.06em;

    &:first-child:after {
      display: inline-block;
      width: 1px;
      height: calc(100% - 6px);
      margin: 0 12px;
      background: $color-grey-1;
      content: "";
    }

    dt {
      margin-right: 5px;
      color: $color-grey-4;
    }

    dd {
      margin: 0;
      color: $color-grey-5;
      letter-spacing: -0.2px;

      em {
        font-style: normal;
      }
    }
  }

  &-desc2 {
    margin: 0;
    font-size: rem(13px);
    color: $color-grey-3;
    letter-spacing: -0.2px;
    line-height: (21/13);

    dt {
      display: inline-block;
      margin: 0 3px 0 0;
      font-weight: 700;
    }

    dd {
      display: inline-block;
      margin: 0 8px 0 0;
      color: #666;
    }
  }

  &-image {
    width: rem(60px);
    margin-left: 9px;
    box-shadow: 3px 4px 12px rgba(142, 156, 173, 0.2);
  }
}
</style>
