<template>
  <div class="ep-foldable-description">
    <div class="ep-foldable-description-title-container">
      <span class="ep-foldable-description-title">{{ title }}</span>
      <span class="ep-foldable-description-fold-button" @click="isFolded = !isFolded">
        <img
          :class="['ep-foldable-description-fold-chevron', isFolded ? 'fold' : '']"
          src="@/assets/icons/chevron-down-icon--grey.png"
          :alt="isFolded ? '펼치기 아이콘' : '닫기 아이콘'"
          role="button"
        />
      </span>
    </div>
    <div v-if="!isFolded" class="ep-foldable-description-content">
      <slot></slot>
      <!--      <ul class="ep-foldable-description-ul">
        <li class="ep-foldable-description-li">
          필요 이상의 신용카드를 발급받을 경우 신용등급 또는 개인신용평점이나<br />
          이용한도 등에 영향을 미칠 수 있습니다.
        </li>
        <li class="ep-foldable-description-li">
          상세혜택 및 이용조건은 계약 체결 전 상품설명서 및 약관을 읽어보시기 바랍니다.<br />
          ※연체이자율 : 회원별, 이용상품별 약정금리 + 최대 3%, 법정 최고금리(24%)이내<br />
          단, 연체 발생 시점에 약정금리가 없는 경우 아래와 같이 적용함<br />
          - 일시불 거래 연체 시 : 거래 발생 시점의 최소기간(2개월) 유이자 할부 금리<br />
          - 무이자 할부 거래 연체 시 : 거래 발생 시점의 동일한 할부 계약기간의<br />
          유이자 할부 금리<br />
          ※신용카드 남용은 가계경제에 위협이 됩니다.<br />
          ※여신금융상품 이용 시 귀하의 신용등급 또는 개인신용평점이 하락할 수 있습니다.
        </li>
      </ul>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "EpFoldableDescription",
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isFolded: true
    };
  }
};
</script>

<style lang="scss">
.ep-foldable-description {
  /*width: 392px;*/
  /*height: 332px;*/
  /*padding: 27px 24px 24px 27px;*/
  padding: 20px 0;
  //border-top: 1px solid $color-grey-1;

  &-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-title {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: -0.5px;
    color: $color-grey-6;

    word-break: break-word;
  }

  &-fold-button {
    width: 24px;
    height: 24px;
    text-align: center;
    cursor: pointer;

    flex-shrink: 0;
    flex-grow: 0;
  }

  &-fold-chevron {
    width: 12px;
    height: 7.41px;
    transform: rotate(180deg);
    vertical-align: middle;

    &.fold {
      transform: rotate(0deg);
    }
  }

  &-content {
    font-weight: normal;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: #666666;
    opacity: 0.8;
    padding: 27px 0 8px;

    p {
      margin: 0;
    }
  }

  &-ul {
    list-style: none;
    padding: 0;
  }

  &-li {
    font-weight: normal;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: -0.2px;
    color: #666666;
    opacity: 0.8;
    background: url("~@/assets/icons/ul-circle-dot-icon--grey.png") no-repeat left 6.5px;
    padding-left: 10px;
  }
}
</style>
