<template>
  <div v-if="supportText" class="ep-benefit" role="text">
    <img v-if="icon" class="ep-benefit-icon" :src="icon" :alt="iconDesc" />
    <span class="ep-benefit-name" v-if="name"> {{ name }}</span>
    <span class="ep-benefit-total">{{ supportText }}</span>
  </div>
</template>

<script>
export default {
  name: "EpBenefit",
  props: {
    icon: {
      type: String,
      default: require("@/assets/icons/won-icon.png")
    },
    iconDesc: {
      type: String,
      default: "원화아이콘"
    },
    name: {
      type: String,
      default: "제휴지원금"
    },
    supportText: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss">
.ep-benefit {
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: -0.006em;
  color: $color-grey-6;

  &-icon {
    width: 19px;
    height: 19px;
  }

  &-name {
    margin-left: 5px;
  }

  &-total {
    font-weight: 700;
    margin-left: 3px;
  }
}
</style>
